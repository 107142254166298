import { local } from '../../../lang/local';
import './logo.css';
import { Col, Row } from 'reactstrap';
import logo from '../../../assets/images/logo_large.png';
import 'aos/dist/aos.css'; // You can also use <link> for styles

export const LogoView = () => {
  return (
    <div data-aos="fade-up"
         data-aos-offset="100"
         data-aos-delay="50"
         data-aos-duration="1000"
         data-aos-easing="ease-in-out"
         data-aos-mirror="true"
         data-aos-once="false"
         data-aos-anchor-placement="top-center"
         className='logo-section'>
      <div className='container z100'>
          <img src={logo} alt=""/>
      </div>
    </div>
  );
};
