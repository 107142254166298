import { local } from '../../../lang/local';
import './CoreValues.css';
import cv1 from '../../../assets/images/core-values/cv1.png';
import cv2 from '../../../assets/images/core-values/cv2.png';
import cv3 from '../../../assets/images/core-values/cv3.png';
import cv4 from '../../../assets/images/core-values/cv4.png';
import cv5 from '../../../assets/images/core-values/cv5.png';
import cv6 from '../../../assets/images/core-values/cv6.png';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import { Row, Col, Card } from 'reactstrap';
import React from 'react';
export const CoreValuesView = () => {
  return (
    <React.Fragment>
      <section className='core-values-section'>
        <div className='container'>
          <h3 className='primaryColor text-center'>{local.OurCore}</h3>
          <Row className='mt-4'>
            <Col xl={10} lg={10} md={12} sm={12} xs={12} className='mx-auto'>
              <Row>
                {[
                  { title: 'Trust', desc: 'ourCoreDesc1', photo: cv1 },
                  { title: 'Response', desc: 'ourCoreDesc2', photo: cv2 },
                  { title: 'Conviction', desc: 'ourCoreDesc3', photo: cv3 },
                  { title: 'Loyalty', desc: 'ourCoreDesc4', photo: cv4 },
                ].map((item, index) => {
                    let dataAos = ""
                    if (index % 2 != 0) {
                        dataAos =  "slide-right"
                    } else {
                        dataAos =  "slide-left"
                    }
                    return (
                    <Col
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className='mx-auto center mt-3'
                    >

                      <div className='text-center card-wrapper'
                           data-aos={dataAos}
                           data-aos-offset="50"
                           data-aos-delay="200"
                           data-aos-duration="1000"
                           data-aos-easing="ease-in-out"
                           data-aos-mirror="true"
                           data-aos-once="false"
                           data-aos-anchor-placement="center-center">
                        <Card className='text-center center general-photo-card photo-card'>
                          <div className='card-container'>
                            <img
                              src={item.photo}
                              alt=''
                              className='imgFull'
                              key={index}
                            />
                          </div>
                          <div className='mt-4 desc'>
                            <p>{local[item.desc]}</p>
                          </div>
                        </Card>
                        <p className='mt-3 title'>{local[item.title]}</p>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </section>
      <div className='core-value-photo-section'
           data-aos="zoom-in-down"
           data-aos-offset="100"
           data-aos-delay="300"
           data-aos-duration="1000"
           data-aos-easing="linear"
           data-aos-mirror="true"
           data-aos-once="false"
           data-aos-anchor-placement="bootom">
        <div className='container'>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mt-3 text-center'>
             <p className='title'>{local.TrackingSystem}</p>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col xl={7} lg={7} md={7} sm={12} xs={12} className='mt-3 text-center mx-auto'>
             <p className='desc'>{local.TrackingDesc}</p>
            </Col>
          </Row>
        </div>
      </div>

      <section className='core-values-section'>
        <div className='container'>
          <Row className='mt-4'>
            <Col xl={10} lg={10} md={12} sm={12} xs={12} className='mx-auto'>
              <Row>
                {[
                  { title: 'Vision', desc: 'ourCoreDesc5', photo: cv5 },
                  { title: 'Mission', desc: 'ourCoreDesc6', photo: cv6 },
                ].map((item, index) => {
                  return (
                    <Col
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className='mx-auto center mt-3'
                    >
                      <div className='text-center card-wrapper'
                           data-aos="flip-down"
                           data-aos-offset="100"
                           data-aos-delay="300"
                           data-aos-duration="1000"
                           data-aos-easing="ease-in-out"
                           data-aos-mirror="true"
                           data-aos-once="false"
                           data-aos-anchor-placement="top">
                        <Card className='text-center center general-photo-card photo-card'>
                          <div className='card-container'>
                            <img
                              src={item.photo}
                              alt=''
                              className='imgFull'
                              key={index}
                            />
                          </div>
                          <div className='mt-4 desc'>
                            <p>{local[item.desc]}</p>
                          </div>
                        </Card>
                        <p className='mt-3 title'>{local[item.title]}</p>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};
