import { BrowserRouter, Switch, useHistory } from 'react-router-dom';
import AuthenticatedRoute from './routing/AuthenticatedRoute';
import { changeLanguage, getLanguage } from './lang/local';
import interceptor from './infrastructure/services/interceptor-service';
import Layout from './routing/Layout/Layout';

function App() {
  const history = useHistory();
  interceptor.setupInterceptors(history);

  return (
    <BrowserRouter>
      <Switch>
        <Layout/>
        {/* <AuthenticatedRoute
          path='/'
          component={Layout}
        /> */}
      </Switch>
    </BrowserRouter>
  );
}
changeLanguage();
if (getLanguage() === 'en') {
  document.body.style.direction = 'ltr';
} else {
  document.body.style.direction = 'rtl';
}
export default App;
