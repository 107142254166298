import { local } from '../../lang/local';
import './AboutUs.css';
import aboutUs from '../../../assets/images/about-us/about-us.png';
import about1 from '../../assets/images/about-us/about1.png';
import about2 from '../../assets/images/about-us/about2.png';
import about3 from '../../assets/images/about-us/about3.png';
import cv5 from '../../assets/images/core-values/cv5.png';

import { Row, Col, Card } from 'reactstrap';
import React from 'react';
export const AboutUsView = () => {
  return (
    <React.Fragment>
      <h3 className='primaryColor text-center bold mt-5'>{local.AboutUs}</h3>
      <section className='about-us-section mt-5'>
        <div className='container'>
          {[
            'AboutUsTitle1',
            'AboutUsTitle2',
            'AboutUsTitle3',
            'AboutUsTitle4',
            'AboutUsTitle5',
          ].map((item, index) => {
            return (
              <Row>
                <Col
                  key={index}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className='mx-auto mt-5'
                >
                  <p className='text-center desc'>{local[item]}</p>
                </Col>
              </Row>
            );
          })}
        </div>
      </section>

      <section className='core-values-section'>
        <div className='container'>
          <Row className='mt-4'>
            <Col xl={10} lg={10} md={12} sm={12} xs={12} className='mx-auto'>
              <Row>
                {[{ title: 'Vision', desc: 'ourCoreDesc5', photo: cv5 }].map(
                  (item, index) => {
                    return (
                      <Col
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className='mx-auto center mt-3'
                      >
                        <div className='text-center card-wrapper'>
                          <Card className='text-center center general-photo-card photo-card'>
                            <div className='card-container'>
                              <img
                                src={item.photo}
                                alt=''
                                className='imgFull'
                                key={index}
                              />
                            </div>
                            <div className='mt-4 desc'>
                              <p>{local[item.desc]}</p>
                            </div>
                          </Card>
                          <p className='mt-3 title'>{local[item.title]}</p>
                        </div>
                      </Col>
                    );
                  }
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </section>
      <section className='staff-section'>
        <h3 className='primaryColor text-center bold mt-5'>{local.Staff}</h3>
        <div className='container'>
          <Row className='mt-4'>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mx-auto'>
              <Row className='mt-5'>
                {[
                  { photo: about1, desc: 'StaffDesc1' },
                  { photo: about2, desc: 'StaffDesc1' },
                  { photo: about3, desc: 'StaffDesc1' },
                ].map((item, index) => {
                  return (
                    <Col
                      key={index}
                      xl={4}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      className='center mx-auto'
                    >
                      <div className='text-center'>
                        <div className='center'>
                          <div className='image-container'>
                            <img src={item.photo} alt='' className='imgFull' />
                          </div>
                        </div>
                        <div className='mt-5 desc'>
                          <p className='mt-4'>{local[item.desc]}</p>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};
