import { local } from '../../../lang/local';
import './BusinessFeatures.css';
import bf1 from '../../../assets/images/business-features/bf1.png';
import bf2 from '../../../assets/images/business-features/bf2.png';
import bf3 from '../../../assets/images/business-features/bf3.png';

import { Row, Col, Card } from 'reactstrap';
export const BusinessFeaturesView = () => {
  return (
    <section className='business-features-section'>
      <div className='container'>
        <h3 className='primaryColor text-center'>{local.AdvantagesWorking}</h3>
        <Row className='mt-4'>
          <Col xl={10} lg={10} md={12} sm={12} xs={12} className='mx-auto'>
            <Row>
              {[
                { title: 'Agriculture', desc: 'ApplicationInternational', photo: bf1 },
                { title: 'Harvest', desc: 'HarvestProcurement', photo: bf2 },
                { title: 'PackingTransportation', desc: 'PackagingTransporting', photo: bf3 },
              ].map((item, index) => {
                return (
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    className='mx-auto center mt-5'
                  >
                    <div className='text-center card-wrapper'
                         data-aos="slide-down"
                         data-aos-offset="200"
                         data-aos-delay="100"
                         data-aos-duration="1000"
                         data-aos-easing="ease-in-cubic"
                         data-aos-mirror="true"
                         data-aos-once="false"
                         data-aos-anchor-placement="center">
                      <Card className='text-center alignItem general-photo-card photo-card'>
                        <div className='card-container mt-5'>
                          <img
                            src={item.photo}
                            alt=''
                            className='imgFull'
                            key={index}
                          />
                        </div>
                        <div className='mt-3 desc'>
                          <p>{local[item.desc]}</p>
                        </div>
                      </Card>
                      <p className='mt-3 title'>{local[item.title]}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};
