import React from 'react';
import { Footer } from '../../components/Footer/Footer';
import { PhotosView } from './PhotosView';

export const PhotosController = () => {
  return (
    <React.Fragment>
      <PhotosView />
      <Footer />
    </React.Fragment>
  );
};
