function getCookie(cookieName: string) {
  const name = `${cookieName}=`;
  const ca = document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setCookie(cookieName: string, cookieValue: string, expireDays = 30) {
  const d = new Date();
  d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
}

function deleteCookie(name: string) {
  if (getCookie(name)) {
    document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }
}

export { setCookie, getCookie, deleteCookie };
