import { local } from '../../../lang/local';
import './OurBrands.css';
import b1 from '../../../assets/images/our-brands/b1.jpeg';
import b2 from '../../../assets/images/our-brands/b2.jpeg';
import b3 from '../../../assets/images/our-brands/b3.jpeg';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import { Card, Col, Row } from 'reactstrap';
import React from 'react';

export const OurBrandsView = () => {
  return (
    <React.Fragment>
      <section className='brand-section'>
        <div className='container'>
          <h3 className='primaryColor text-center'>{local.OurBrands}</h3>
          <Row className='mt-4'>
            <Col xl={10} lg={10} md={12} sm={12} xs={12} className='mx-auto'>
              <Row>
                {[b1, b2, b3].map((item, index) => {
                  return (
                    <Col
                      xl={4}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      className='mx-auto center mt-5'
                    >
                      <Card className='center general-photo-card photo-card'>
                        <div className='card-container'
                        data-aos="fade-up"
                        data-aos-offset="50"
                        data-aos-delay="50"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="center-center">
                          <img
                            src={item}
                            alt=''
                            width='200'
                            height='200'
                            className='imgFull'
                            key={index}
                          />
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </section>

      <div className='brand-photo-section'
           data-aos="zoom-in"
           data-aos-offset="50"
           data-aos-delay="200"
           data-aos-duration="1000"
           data-aos-easing="ease-in-out"
           data-aos-mirror="true"
           data-aos-once="false"
           data-aos-anchor-placement="top-center">
        <div className='brightness-wrapper'/>
        <div className='container z100'>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mt-3'>
              <ul className='brand-list'>
                <li>{local.brandList1}</li>
                <li>{local.brandList2}</li>
                <li>{local.brandList3}</li>
                <li>{local.brandList4}</li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
