export const ar = {
  lang: 'ar',
  English: 'الانجليزية',
  Arabic: 'العربية',
  Products: 'المنتجات',
  Photos: 'الصور',
  TheQuality: 'الجودة',
  OurOffices: 'مكاتبنا',
  ShippingTransportation: 'الشحن و النقل',
  AboutUs: 'من نحن ؟',
  ContactUs: 'تواصل معنا',
  ChooseBestProducts: 'اختر افضل المنتجات الصحية',
  SectionOneDisc:
    'تأسست مجموعة الفرسان للأستيراد والتصدير في السوق المصري منذ عام 2013، ويعتمد مجالها الرئيسي على الغذاء وإدارة الثلاجات والتصدير والاستيراد والتوزيع المحلي والخدمات اللوجستية المتكاملة المتوافقة مع متطلبات وإشتراطات الجودة المعتمدة من قبل الشركات العالمية، مع الاهتمام بتوفير كافة احتياجات السوق العالمى من الحاصلات الزراعية من الفواكة والخضروات ذات الجودة العالية',
  OurBrands: 'علاماتنا التجارية',
  brandList1:
    'سوف نقوم بأرسال قائمة بالمنتجات تشمل المواصفات والأحجام وصور من المنتجات',
  brandList2:
    'ملتزمون بتقديم أفضل جودة وتطبيق اشتراطات الجودة العالمية وتحديثاتها',
  brandList3:
    'دعم فنى كامل بأحدث الأنظمة العالمية للمساعدة والمتابعة والرد على كافة استفساراتكم',
  brandList4: 'جميع المنتجات مطابقة لمواصفات الجودة والمعايير الدولية',
  Classifications: 'التصنيفات',
  OurCrops: 'مزروعاتنا قادمة من مزارع معتمدة ومطابقة للمواصفات',
  Meat: 'لحوم',
  Fish: 'أسماك',
  Certificates: 'الشهادات المعتمدة',
  AdvantagesWorking: 'مميزات العمل مع الفرسان',
  ApplicationInternational: 'تطبيق المعايير الدولية فى الزراعة والرى',
  HarvestProcurement:
    'حصاد بأحدث الأساليب وفقاً للأشتراطات الدولية ومعايير الجودة',
  PackagingTransporting:
    'تعبئة ونقل المنتجات فى ظروف صحية سليمة وفقاً لسلسلة من الأجراءات والأشتراطات لتطبيق معايير الجودة والصحة الدولية.',
  OurCore: 'قيمنا الأساسية',
  ourCoreDesc1:
    'القدرة على تقديم تجربة متميزة للعملاء بشكل دقيق ومتميز وذلك بتطبيق منظومة الجودة أولاً، مما يترتب عليه الحصول على رضا العميل ورفع درجة ولاء العميل وقياس معدل الإحتفاظ بالعملاء',
  ourCoreDesc2:
    'التعامل بكفاءة مع جميع متطلبات العملاء والاستجابة للشكاوى وحلها بسهولة فى الوقت المناسب',
  ourCoreDesc3:
    'تعني حرص موظفو شركة الفرسان على غرس الثقة والشعور بالأمان عند تلبية احتياجات العملاء، والاقتناع يتم نتيجة المعرفة القوية للموظفين بمهامهم والمنتجات المعروضة وكافة الجوانب الفنية.',
  ourCoreDesc4:
    'يمكن الوصول لمرحلة ولاء العميل مع العلامة التجارية من خلال تقديم الرعاية والاهتمام الفردي لكل عميل بأعلى مستوى جودة وتقديم كافة المساعدات والإجابة على كافة الإستفسارات للعميل من خلال منظومة مستوى الخدمة المقدمة “S.L.A”',
  ourCoreDesc5: 'تتمثل رؤيتنا في الحفاظ على مكانتنا في تصدير الخضروات الطازجة وتوسيع شبكة مبيعاتنا خاصة في الخارج. أن نكون رائدين في مجال المنتجات الزراعية، ونقدم طرق إنتاج جديدة ومبتكرة، ونعمل على تحسين الجودة باستمرار والتحكم في تكاليف الإنتاج.',
  ourCoreDesc6:
    'تقدم شركة الفرسان أقصى معايير جودة تعبئة وتخزين المنتجات الغذائية والحاصلات الزراعية مع توافر كافة الخدمات اللوجيستية للوصول إلي رضا كافة العملاء وتحقيق كافة إحتياجاتهم بأعلي جودة وأفضل خدمة لوجيستية وفقًا لمعايير رقابة وتوكيد الجودة الشاملة',
  Agriculture: 'الزراعة',
  Harvest: 'الحصاد',
  PackingTransportation: 'التعبئة والنقل',
  Trust: 'الثقة',
  Response: 'الإستجابة',
  Conviction: 'الاقتناع',
  Loyalty: 'الولاء',
  Vision: 'رؤيتنا',
  Mission: 'المهمة',
  TrackingSystem: 'نظام التتبع',
  TrackingDesc:
    ' تطبق شركة الفرسان نظام الجودة الشاملة من المزرعة  ونظام يسمح للعميل  بتتبع المنتج منذ حصاده وخروجه من المزرعة مرورًا بعمليات النقل إلى محطة الفرز والتعبئة والتغليف إلى التخزين والشحن وصولاً إلى العميل النهائي (Quality Check for Focal Points).',
  GetInTouch: 'تواصل معنا',
  FullName: 'الأسم بالكامل',
  Email: 'البريد الإلكتروني',
  Mobile: 'رقم الهاتف',
  City: 'المدينة',
  Inquiry: 'استعلام',
  WriteQuery: 'اكتب استفسار',
  Send: 'ارسال',
  ZakeEstablished:
    'تأسست مجموعة الفرسان للاستيراد والتصدير في السوق المصري منذ عام 2012',
  Links: 'الروابط',
  Address: 'العنوان',
  StreetAddress: 'شارع الحرية 58 عمارة 5',
  ZipNumber: 'رقم بريدي 133254',
  PhoneMail: 'الهاتف والبريد',
  AboutUsTitle1:
    'الفرسان للاستيراد والتصدير هي إحدى الشركات المصرية الرائدة في مجال تصدير الفواكه والخضروات الطازجة، وتقع في القاهرة، مصر. على مدار العام، توفر مجموعة كاملة من الفواكه والخضروات الطازجة لعملائها: المستوردين وتجار الجملة وسلاسل المتاجر الكبرى ومؤسسات تقديم الطعام والصناعات التحويلية .  توفر شركة الفرسان للاستيراد والتصدير خدمات شاملة ومتكاملة لعملائها في جميع أنحاء العالم. نعمل في أكثر من 50 دولة حول العالم، مع احترام المواسم\n' +
      '     نحن الفرسان للاستيراد والتصدير! ... نحن مصدر ومنتج للفواكه والخضروات الطازجة .\n' +
      '    تأسست شركتنا عام 2012 كواحدة من الشركات الموثوقة في مجال إنتاج وتوزيع الخضار والفواكه الطازجة',
  AboutUsTitle2:
    'رؤيتنا هي الحفاظ على مكانتنا في تصدير الخضار الطازجة وتوسيع شبكة مبيعاتنا، وخاصة في الخارج.\n' +
      'أن نكون رائدين في مجال المنتجات الزراعية، ونقدم طرق إنتاج جديدة ومبتكرة، ونعمل على تحسين الجودة باستمرار والتحكم في تكاليف الإنتاج.',
  AboutUsTitle3: 'أهدافنا\n' +
      '     \n' +
      '        • توفير خدمة عملاء متسقة وفعالة وسريعة.\n' +
      '        • تقديم مجموعة كاملة من المنتجات للعملاء.\n' +
      '        • تحسين جودة منتجاتنا واتباع إرشادات نظام ضمان الجودة.\n' +
      '        • الحفاظ على بيئة صحية تمامًا والامتثال الصارم للتشريعات الجديدة ذات الصلة.',
  AboutUsTitle4:
    'التعبئة والتغليف أي فواكه أو خضروات مطلوبة مقدمة لعملائنا وفقًا لاحتياجاتهم. نبدأ بتغليف منتجاتنا في عبوات صديقة للبيئة تعتمد على مواد متجددة وقابلة لإعادة التدوير وطبيعية. تم تقليل كمية العبوة. يساهم التغليف في سلامة وجودة وراحة المنتجات الغذائية للمستهلكين. يمكن لمحطات الوقود الحديثة تقديم جميع أنواع خدمات التعبئة والتغليف، العامة والفردية. هناك العديد من أنواع التغليف المرغوبة المتوفرة، حتى العبوات الصغيرة التي تحمل ملصقًا خاصًا ورمزًا شريطيًا. تلتزم شركة الفرسان للتصدير والاستيراد بابتكار وتحسين مجموعة منتجاتها وأنظمة التعبئة والتغليف والتسليم. ونتيجة لذلك، تستمر جودة ونضارة منتجاتنا في التحسن، مما يسمح لنا بتلبية متطلباتك على أفضل وجه.',
  AboutUsTitle5:
    'الخدمات اللوجستية خبرة كبيرة في تقديم الحلول اللوجستية الفعالة. مكون حزمة الخدمة الفريد شركة الفرسان للتصدير والاستيراد هي شريك النقل المحترف والمتخصص بالكامل في نقل الفواكه والخضروات الطازجة. تقوم شركة الفرسان للتصدير والاستيراد بتنسيق أنشطتها مع الشاحنات العالمية التي تنقل المنتجات يوميًا من محطات الوقود لدينا إلى مختلف عملاء التجزئة حول العالم. علاوة على ذلك، فإن مخططي النقل المتميزين يمكّنون شركة الفرسان للتصدير والاستيراد من التوزيع وفقًا للجدول الزمني الموعود، مما يضمن التسليم في الوقت المناسب في جميع أنحاء العالم. تم تجهيز كل شاحنة ومقطورة وفقًا لأحدث المعايير لضمان الظروف المثالية أثناء عملية النقل، مما يضمن جودة المنتج.',
  Staff: 'فريق العمل',
  StaffDesc1:
    'تسعى شركة الفرسان  إلى الإنتشار والتوسع الأفقي والرأسي فى مجال التصدير والاستيراد بتطبيق مفهوم إدارة الجودة الشاملة',
  Strawberry: 'فراولة',
  Artichoke: 'خرشوف',
  PeeledBroadGreenBeans: 'فول اخضر مقشر',
  Broccoli: 'بروكلي',
  Cauliflower: 'قرنبيط',
  ConeSpinach: 'سبــانـــخ مخروطــة',
  LeafSpinach: 'سبــانـــخ اوراق',
  SweetCornCobs: 'ذرة حلــــــوة (كيزان)',
  SweetCorn: 'ذرة حلــــــوة',
  Mango: 'مانجــو',
  Mix: 'كوكتيل',
  Pomegranates: 'رمـــــــان',
  Guava: 'جوافــة',
  Apricot: 'مشمش',
  Fig: 'تيــن',
  PricklyPear:'تين شوكى',
  Dates: 'بلـــــح',
  Zucchini: 'كوسة',
  GrapeLeaves:'ورق عنب',
  Eggplant:'باذنجان',
  Tomatoes: 'طماطم',
  BabaGhanoush:'بابا غنوج',
  pepper:'فلفل',
  Qalqas:'قلقاس',
  Onions: 'بصل',
  GreenBean: 'فول اخضر',
  Potatoes: 'بطاطس',
  ConeMolokhia: 'ملوخية مخروطــة',
  CaliforniaMix: 'كاليفورنبا ميكس',
  WinterBlend: 'وينتر بلند',
  SweetPotato: 'بطاطا',
  Leek: 'كرات',
  Pears: 'كمثري',
  Peach: 'خوخ',
  Apple: 'تفاح',
  Plum: 'برقوق',
  Lemon: 'ليمون',
  Orange: 'برتقال',
  Mandarin: 'يوسفي',
  PineApple: 'اناناس',
  GreenBeans: 'فاصوليا خضراء',
  GreenPeas: 'بازلاء خضراء',
  GreenSpinach: 'سبانخ خضراء',
  PeasCarrots: 'بسلة وجزر',
  Carrot: 'جزر',
  MixedVegetables: 'خضـــــار مشكـــــل ثرى واى',
  MixedVegetables4y: 'خضـــــار مشكـــــل فور واى',
  VegetablesForSoup: 'خضار للشوربة',
  GreenOkraZero: 'بامية زيرو خضراء',
  GreenOkra: 'بامية خضراء',
  GreenLeafMolokhia: 'ملوخية ورق خضراء',
  FreshVegetables: 'خضروات طازجة',
  FreshVegetablesDesc: 'أفضل أنواع الخضروات الطازجة تمامًا، ومليئة بالنكهة، وخالية من العيوب. إنها شهادة على الزراعة والرعاية الفائقة، حيث تقدم جوهر المنتجات النقية والنابضة بالحياة واللذيذة.',
  FreshFruits: 'فواكه طازجة',
  FreshFruitsDesc: 'فضل أنواع الفواكه تتميز بشكل رائع، وتعرض ذروة النضج، ونكهة رائعة، وقشرة لا تشوبها شائبة. إنها تشهد على كمال الطبيعة وزراعتها الفائقة، مما يوفر حلاوة ونضارة شهية في كل قضمة.',
  FrozenVegetables: 'خضروات مجمدة',
  FrozenVegetablesDesc: 'تحتفظ أفضل أنواع الخضروات المجمدة بأقصى درجة من النضارة والنكهة التي تتمتع بها نظيراتها التي تم حصادها للتو. تحافظ هذه الخضروات، المجمدة سريعًا في بدايتها، على ألوانها النابضة بالحياة وقوامها الهش وقيمتها الغذائية، مما يوفر إضافة مريحة ومغذية لأي وجبة. يتم الحفاظ على جودتها الطبيعية، مما يضمن خيارًا لذيذًا وصحيًا لأولئك الذين يبحثون عن راحة المنتجات المجمدة دون المساومة على الجودة.',
  FrozenFruits: 'فواكه مجمدة',
  FrozenFruitsDesc: 'تحتفظ أفضل أنواع الفواكه المجمدة بأقصى درجة من النضارة والنكهة التي تتمتع بها نظيراتها التي تم حصادها للتو. تحافظ هذه الخضروات، المجمدة سريعًا في بدايتها، على ألوانها النابضة بالحياة وقوامها الهش وقيمتها الغذائية، مما يوفر إضافة مريحة ومغذية لأي وجبة. يتم الحفاظ على جودتها الطبيعية، مما يضمن خيارًا لذيذًا وصحيًا لأولئك الذين يبحثون عن راحة المنتجات المجمدة دون المساومة على الجودة.',
  SeaFood: 'ماكولات بحرية',
  SeaFoodDesc:"تتميز أفضل جودة للمأكولات البحرية بنضارتها التي لا لبس فيها، ورحلتها من المحيط إلى الطبق، ونكهتها الأصلية. مصدرها مصايد الأسماك المستدامة وذات السمعة الطيبة، وهي تجسد أعلى معايير الجودة وممارسات الصيد المسؤولة. يتم التعامل مع عروض المأكولات البحرية هذه وتخزينها وتسليمها بخبرة لضمان المذاق والملمس الأمثل.",
  Shrimp: 'جمبري',
  Squid: 'سبيط',
  Crab: 'كابوريا',
  TilapiaFish: 'بطلي',
  MulletFish: 'بوري',
  MackerelFish: 'مكاريل',
  Sweets: 'حلويات',
  Soup: 'شوربة',
  Spices: 'بهارات',
  Snacks: 'وجبات خفيفة',
  Juices: 'عصائر',
  JuicesDesc: 'عصائر طبيعية عالية الجودة مصنوعة من فاكهتنا الطازجة.',
  quailtyTitle1:
    'تهتم الفرسان بجودة عملية الزراعة والإنتاج بشكل عام والنقل والشحن لتحقيق جودة المنتجات النهائية.',
  quailtyTitle2:
    'تعمل الشركة على تأكيد جودة المرافق المادية المرتبطة بتقديم الخدمات والمنتجات وتشمل المظهر العام لمباني المؤسسة، الانتشار الجغرافي لفروعها، المعدات المستخدمة، مظهر الموظفين، وجودة أدوات الاتصال وتطبيق إشتراطات الشئون الصحية والسلامة والصحة المهنية، بالإضافة إلى المطبوعات والكتيبات والكتالوجات.',
  quailtyTitle3:
    'تولي الشركة اهتمام خاص بتقديم تجربة متميزة للعملاء بتطبيق منظومة الجودة، مما يترتب عليه الحصول على رضا العميل ورفع درجة ولاء العميل لقياس معدل الإحتفاظ بالعملاء.',
  quailtyTitle4:
    'بفضل اهتمام الشركة بتحقيق الجودة، فقد نجحت في الحصول على شهادتي ضبط الجودة والتوكيد ISO9001 و ISO22000.',
  shippingTitle1:
    'انشأت شركة الفرسان نظام تسليم قوي ودقيق يضمن سلامة وجودة المنتجات لضمان جودة عمليات الشحن والنقل، وتعتمد عملية الشحن على جاهزية الخضروات والفواكه التي تصدرها الشركة، من خلال التجهيز الفردي والتعبئة وفقًا لمعايير الجودة العالمية.',
  shippingTitle2:
    'وتعتمد الفرسان في عمليات الشحن على شركاء ذوي مسئولية كبيرة وكفاءة في العمل إلى جانب أسعار تنافسية لإنجاز العمل بدقة.',
  Office: 'المكتب',
  CommunicateWithUs: 'يمكنك ان تتواصل معنا في اي وقت !',
  officeTitle:'توسعت شركة الفرسان أجرو في إنشاء مكاتب لها لتكون حلقة وصل بينها وبين عملائها حول العالم، حيث افتتحت مكاتبها في (الإمارات العربية المتحدة، جمهورية الصين الشعبية، هولندا ، السعودية ، ليبيا) وجارى التوسع بمكاتب جديدة في عدد من الدول المستهدفة'
};
