import { createSlice } from '@reduxjs/toolkit';
import { SIGN_IN_USER, LOGOUT_USER } from '../actions/auth.action';
import { RootState } from '../store';
// Imported actions

const initialState = {
  isLoggedIn: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInUser: SIGN_IN_USER,
    logoutUser: LOGOUT_USER,
  },
});

export const auth = (state: RootState) => state.auth;

export const { signInUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;
