import { Input, Label } from 'reactstrap';
import { getLanguage, local } from '../../lang/local';
import '../CustomInput/CustomInput.css';
export const CustomTextArea = ({ title, placeholder }: any) => {
  return (
    <div className={getLanguage() === 'en' ? 'text-start' : 'text-end'}>
      <Label className='label_input'>{local[title]}</Label>
      <div>
        <Input
          id='input-form-style'
          type='textarea'
          name={title}
          cols='10'
          rows='10'
          placeholder={local[placeholder]}
          style={{height:'fit-content'}}
        />
      </div>
    </div>
  );
};
