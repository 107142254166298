import { local } from '../../../lang/local';
import './Certificates.css';
import cert1 from '../../../assets/images/certificates/cert1.png';
import cert2 from '../../../assets/images/certificates/cert2.png';
import cert3 from '../../../assets/images/certificates/cert3.png';

import { Row, Col, Card } from 'reactstrap';
export const CertificatesView = () => {
  return (
    <section className='certificates-section'>
      <div className='container'>
        <h3 className='primaryColor text-center'>{local.Certificates}</h3>
        <Row className='mt-4'>
          <Col xl={10} lg={10} md={12} sm={12} xs={12} className='mx-auto'>
            <Row>
              {[cert1, cert2, cert3].map((item, index) => {
                return (
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    className='mx-auto center mt-5'
                  >
                    <Card className='center general-photo-card photo-card'>
                      <div className='card-container'
                           data-aos="zoom-in"
                           data-aos-offset="400"
                           data-aos-delay="100"
                           data-aos-duration="1000"
                           data-aos-easing="linear"
                           data-aos-mirror="true"
                           data-aos-once="false"
                           data-aos-anchor-placement="center">
                        <img
                          src={item}
                          alt=''
                          className='imgFull'
                          key={index}
                        />
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};
