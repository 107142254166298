import ff3 from "../assets/images/products/frozen-fruits/p40.jpg";
import ff4 from "../assets/images/products/frozen-fruits/p41.jpg";
import ff5 from "../assets/images/products/frozen-fruits/p42.jpg";
import ff6 from "../assets/images/products/frozen-fruits/p43.jpg";
import ff7 from "../assets/images/products/frozen-fruits/p44.jpg";
import ff8 from "../assets/images/products/frozen-fruits/p45.jpg";
import ff9 from "../assets/images/products/frozen-fruits/p46.jpg";
import shrimp from "../assets/images/products/seafoods/Shrimp.jpg";
import Squid from "../assets/images/products/seafoods/Squid.jpg";
import Crab from "../assets/images/products/seafoods/Crab.jpg";
import TilapiaFish from "../assets/images/products/seafoods/TilapiaFish.jpg";
import MulletFish from "../assets/images/products/seafoods/MulletFish.jpg";
import MackerelFesh from "../assets/images/products/seafoods/MackerelFesh.jpg";

export const en = {
  lang: 'en',
  English: 'English',
  Arabic: 'Arabic',
  Products: 'Products',
  Photos: 'Photos',
  TheQuality: 'The Quality',
  OurOffices: 'Our Offices',
  ShippingTransportation: 'Shipping and Transportation',
  AboutUs: 'About Us ?',
  ContactUs: 'Contact Us',
  ChooseBestProducts: 'Choose the best health products',
  SectionOneDisc:
    'Al Forsan Import and Export is one of the leading Egyptian companies in the field of export of fresh fruits and vegetables, located in Cairo, Egypt. Throughout the year, it supplies a full range of fresh fruit and vegetables to its customers: importers, wholesalers, supermarket chains, catering establishments and processing industries.',
  OurBrands: 'Our Brands',
  brandList1:
    'Provide consistent, efficient and fast customer service.',
  brandList2:
    'Offer customers a full range of products',
  brandList3:
    'Improve the quality of our products and follow the guidelines of the quality assurance system.',
  brandList4: 'Maintaining a completely healthy environment and strict compliance with relevant new legislation.',
  Classifications: 'Classifications',
  OurCrops: 'Our crops come from certified farms that meet specifications.',
  FreshVegetables: 'Fresh Vegetables',
  FreshVegetablesDesc: 'The best quality vegetables are impeccably fresh, bursting with flavor, and free from blemishes. They are a testament to superior farming and care, delivering the essence of pure, vibrant, and delicious produce.',
  FreshFruits: 'Fresh Fruits',
  FreshFruitsDesc: 'The best quality fruits are a sensory delight, showcasing peak ripeness, exquisite flavor, and flawless, unblemished skins. They testify to nature\'s perfection and superior cultivation, offering a mouthwatering sweetness and freshness in every bite.',
  FrozenVegetables: 'Frozen Vegetables',
  FrozenVegetablesDesc: 'The best quality frozen vegetables retain the peak freshness and flavor of their just-harvested counterparts. Flash-frozen at their prime, these vegetables maintain their vibrant colors, crisp textures, and nutritional value, delivering a convenient and nutritious addition to any meal. Their natural goodness is preserved, ensuring a delicious and healthy option for those seeking the convenience of frozen produce without compromising on quality.',
  FrozenFruits: 'Frozen Fruits',
  FrozenFruitsDesc: 'The best quality frozen Fruits retain the peak freshness and flavor of their just-harvested counterparts. Flash-frozen at their prime, these vegetables maintain their vibrant colors, crisp textures, and nutritional value, delivering a convenient and nutritious addition to any meal. Their natural goodness is preserved, ensuring a delicious and healthy option for those seeking the convenience of frozen produce without compromising on quality.',
  SeaFood: 'Sea Food',
  SeaFoodDesc:"The best seafood quality is marked by its unmistakable freshness, ocean-to-plate journey, and pristine flavor. Sourced from sustainable and reputable fisheries, it embodies the highest standards of quality and responsible harvesting practices. These seafood offerings are expertly handled, stored, and delivered to ensure optimal taste and texture.",
  Shrimp: 'Shrimp',
  Squid: 'Squid',
  Crab: 'Crab',
  TilapiaFish: 'Tilapia Fish',
  MulletFish: 'Mullet Fish',
  MackerelFish: 'Mackerel Fish',
  Meat: 'Meat',
  Certificates: 'Certified Certificates',
  AdvantagesWorking: 'The Advantages of Working with Alfursan',
  ApplicationInternational:
    'Application of international standards in agriculture and irrigation',
  HarvestProcurement:
    'Harvest in the latest methods in accordance with international procurement and quality standards',
  PackagingTransporting:
    'Packaging and transporting products in healthy health conditions in accordance with a series of procedures and procedures for the application of international quality and health standards.',
  OurCore: 'Our Core Values',
  ourCoreDesc1:
    "'The ability to provide a distinct experience for customers accurately and distinctly by applying the quality system first, resulting in obtaining customer satisfaction, raising the customer's loyalty and measuring the retention rate of customers'",
  ourCoreDesc2:
    'Efficiently deal with all customer requirements and respond to complaints and resolve them easily in a timely manner',
  ourCoreDesc3:
    'It means the keenness of Alfursan  employees to instill confidence and a sense of security when meeting the needs of customers, and conviction is the result of strong knowledge of employees of their tasks, products offered and all technical aspects.',
  ourCoreDesc4:
    'The customer loyalty phase with the brand can be reached by providing individual care and attention to each customer of the highest quality and providing all assistance and answering all queries to the customer through the service level system provided "S.L.A."',
  ourCoreDesc5:
    'Our vision is to maintain our position in the export of fresh vegetables and further expand our sales network, especially abroad To be a pioneer in the field of agricultural products, introducing new innovative production methods, constantly improving quality and controlling production costs.',
  ourCoreDesc6:
    'Alfursan  provides the highest standards of quality packaging and storage of food products and agricultural products with the availability of all logistics services to reach the satisfaction of all customers and achieve all their needs in the highest quality and the best logistics service in accordance with the standards of control and confirmation of overall quality',
  Agriculture: 'Agriculture',
  Harvest: 'Harvest',
  PackingTransportation: 'Packing and Transportation',
  Trust: 'Trust',
  Response: 'Response',
  Conviction: 'Conviction',
  Loyalty: 'Loyalty',
  Vision: 'Vision',
  Mission: 'Mission',
  TrackingSystem: 'Experience',
  TrackingDesc:
    ' Al Forsan Import and Export provides comprehensive and integrated services to its clients around the world. We operate in more than 50 countries around the world, respecting the seasons.We are Al Forsan for import and export! ... we are an exporter and producer of fresh fruits and vegetables. Our company was founded in 2012 as one of the most reliable companies in the field of production and distribution of fresh vegetables and fruits.',
  GetInTouch: 'Get in touch with us.',
  FullName: 'FullName',
  Email: 'Email',
  Mobile: 'Mobile',
  City: 'City',
  Inquiry: 'Inquiry',
  WriteQuery: 'اكتب استفسار',
  Send: 'Send',
  ZakeEstablished:
    'Afursan Import and Export Group has been established in the Egyptian market since 2012',
  Links: 'Links',
  Address: 'Address',
  StreetAddress: 'Al hurreya Street 58 Building 5',
  ZipNumber: 'zip Numbr 133254',
  PhoneMail: 'Phone and Email',
  AboutUsTitle1:
    'Al Forsan Import and Export is one of the leading Egyptian companies in the field of export of fresh fruits and vegetables, located in Cairo, Egypt. Throughout the year, it supplies a full range of fresh fruit and vegetables to its customers: importers, wholesalers, supermarket chains, catering establishments and processing industries.\n' +
      '\n' +
      'Al Forsan Import and Export provides comprehensive and integrated services to its clients around the world. We operate in more than 50 countries around the world, respecting the seasons.\n' +
      'We are Al Forsan for import and export! ... we are an exporter and producer of fresh fruits and vegetables.\n' +
      'Our company was founded in 2012 as one of the most reliable companies in the field of production and distribution of fresh vegetables and fruits\n',
  AboutUsTitle2:
    'Our vision is to maintain our position in the export of fresh vegetables and further expand our sales network, especially abroad.\n' +
      'To be a pioneer in the field of agricultural products, introducing new innovative production methods, constantly improving quality and controlling production costs.',
  AboutUsTitle3:
    '\n' +
      '  Our goals\n' +
      '\n' +
      '  • Provide consistent, efficient and fast customer service.\n' +
      '  • Offer customers a full range of products.\n' +
      '  • Improve the quality of our products and follow the guidelines of the quality assurance system.\n' +
      '  • Maintaining a completely healthy environment and strict compliance with relevant new legislation.\n' +
      '  Respect the environment.\n' +
      '\n',
  AboutUsTitle4:
    'Package\n' +
      '\n' +
      '   Packing any required fruits or vegetables offered to our clients according to their needs.\n' +
      '  We start by packaging our products in more environmentally friendly packaging based on renewable, recyclable and natural materials. The amount of packaging has been reduced. Packaging contributes to the safety, quality and convenience of food products for consumers.\n' +
      '  Modern gas stations can provide all types of packaging services, both general and individual.\n' +
      '  There are many desired packaging types available, even small packages with special label and barcode.\n' +
      '\n' +
      'Al Forsan Export and Import is committed to innovating and improving its product range, packaging and delivery systems.\n' +
      '  As a result, the quality and freshness of our products continues to improve, allowing us to best meet your requirements.',
  AboutUsTitle5:
    "  Logistics services\n" +
      "\n" +
      "  Significant experience in providing effective logistics solutions.\n" +
      "   Unique Service Pack Component\n" +
      "\n" +
      "   Al Forsan Export and Import is its professional transport partner, which is completely specialized in the transport of fresh fruits and vegetables.\n" +
      "   Al Forsan Export and Import coordinates its activities with global trucks that transport products daily from our gas stations to various retail customers around the world.\n" +
      "   Moreover, excellent transportation planners enable Al Forsan Export and Import to distribute as per the promised schedule, ensuring timely delivery worldwide.\n" +
      "   Each truck and trailer is equipped to the latest standards to ensure optimal conditions during the transportation process, guaranteeing product quality.\n" +
      "\n'",
  Staff: 'Staff',
  StaffDesc1:
    'Alfursan seeks to spread and expand horizontally and vertically in the field of export and import by applying the concept of comprehensive quality management',
  Strawberry: 'Strawberry',
  Artichoke: 'Artichoke',
  PeeledBroadGreenBeans: 'Peeled Broad Green Beans',
  Pears: 'Pears',
  Peach: 'Peach',
  Apple: 'Apple',
  Plum: 'Plum',
  Lemon: 'Lemon',
  Orange: 'Orange',
  Mandarin: 'Mandarin',
  PineApple: 'Pineapple',
  Mango: 'Mango',
  Mix: 'Mix',
  Pomegranates: 'Pomegranates',
  Guava: 'Guava',
  Apricot: 'Apricot',
  Fig: 'Fig',
  PricklyPear:'Prickly Pear',
  Dates: 'Dates',
  GreenBeans: 'Green Beans',
  GreenPeas: 'Green Peas',
  GreenSpinach: 'Green Spinach',
  PeasCarrots: 'Peas Carrots',
  Carrot: 'Carrot',
  MixedVegetables: 'Mixed Vegetables 3y',
  MixedVegetables4y: 'Mixed Vegetables 4y',
  VegetablesForSoup: 'Vegetables For Soup',
  GreenOkraZero: 'Green Okra Zero',
  GreenOkra: 'Green Okra',
  GreenLeafMolokhia: 'Green Leaf Molokhia',
  ConeMolokhia: 'Cone Molokhia',
  CaliforniaMix: 'California Mix',
  WinterBlend: 'Winter Blend',
  LeafSpinach: 'Spinach Leaf',
  SweetCornCobs: 'Sweet Corn Cobs',
  SweetCorn: 'Sweet Corn',
  Zucchini: 'Zucchini',
  GrapeLeaves:'Grape leaves',
  Eggplant:'Eggplant',
  Tomatoes: 'Tomatoes',
  BabaGhanoush:'Baba Ghanoush',
  pepper:'Pepper',
  Qalqas: 'Qalqas',
  Onions: 'Onions',
  GreenBean: 'Green Bean',
  Potatoes: 'Potatoes',
  Broccoli: 'Broccoli',
  Cauliflower: 'Cauliflower',
  ConeSpinach: 'Cone Spinach',
  SweetPotato: 'Sweet Potato',
  Leek: 'Leek',
  Sweets: 'Sweets',
  Soup: 'Soup',
  Spices: 'Spices',
  Snacks: 'Snacks',
  Juices: 'Juices',
  JuicesDesc: 'The best quality Juices made from our fresh Fruits.',
  quailtyTitle1:
    'Alfursan is concerned with the quality of agriculture, production in general, transportation and shipping to achieve the quality of the finished products.',
  quailtyTitle2:
    "'The company emphasizes the quality of physical facilities associated with the provision of services and products, including the general appearance of the foundation's buildings, the geographical spread of its branches, the equipment used, the appearance of employees, the quality of communication tools and the application of health, safety and occupational health subscriptions, as well as publications, brochures and catalogues.'",
  quailtyTitle3:
    "'The company pays special attention to providing a distinct experience to customers by applying the quality system, resulting in obtaining customer satisfaction and raising the customer's loyalty to measure the retention rate of customers.'",
  quailtyTitle4:
    'Thanks to its interest in quality achievement, the company has succeeded in obtaining quality control and confirmation certificates ISO9001 and ISO22000.',
  shippingTitle1:
    'Alfursan has established a strong and accurate delivery system that ensures the safety and quality of products to ensure the quality of shipments and transportation, and the shipping process depends on the readiness of vegetables and fruits exported by the company, through individual processing and packaging in accordance with international quality standards.',
  shippingTitle2:
    "'Alfursan shipping operations rely on highly responsible and efficient partners as well as competitive prices to get the job done accurately.'",
    Office:'Office',
    CommunicateWithUs:'You can communicate with us at any time!',
    officeTitle:"'Afursan has expanded to establish offices to be a link between it and its customers around the world, opening its offices in (UAE, People's Republic of China, Netherlands, Saudi Arabia, Libya) and expanding new offices in a number of target countries'"
  };
