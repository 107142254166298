import { useEffect } from 'react';

let map;
export const MapContainer = () => {
  useEffect(() => {
    const ApiKey = 'AIzaSyC7ZO1PWmqpBeCwX-zOIZHff2yPHTigS5Y';
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey}`;
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
    script.addEventListener('load', () => {
      initMap();
    });
  }, []);
  const initMap = () => {
    map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: 11,
      center: {
        lat: 30,
        lng: 31,
      },
      disableDefaultUI: true,
      clickableIcons: false,
    });

    if (map) {
      new window.google.maps.Marker({
        position: { lat: 30, lng: 31 },
        title: 'ALFURSAN',
        map,
      });
    }
  };
  return (
    <div
      id='map'
      style={{ width: '100%', height: '100vh', borderRadius: '10px' }}
    />
  );
};
