import { local } from '../../lang/local';
import './OurOffices.css';
import { Row, Col, Card } from 'reactstrap';
import { MapContainer } from './MapContainer';
export const OurOfficesView = () => {
  return (
    <section className='office-section'>
      <div className='container'>
        <h3 className='primaryColor text-center bold'>{local.OurOffices}</h3>
        <Row className='mt-4'>
          <p className='title mt-5'>{local.officeTitle}</p>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className='mx-auto center mt-3 text-start'
          >
            <Card className='text-center alignItem general-photo-card photo-card'>
              <MapContainer />
            </Card>
          </Col>
        </Row>
      </div>
    </section>
  );
};
