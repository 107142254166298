import { local } from '../../lang/local';
import './GetInTouch.css';
import { Row, Col } from 'reactstrap';
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { CustomTextArea } from '../../components/CustomTextArea/CustomTextArea';
import { CustomButton } from '../../components/CustomButton/CustomButton';
export const GetInTouchView = ({ title, col }: any) => {
  return (
    <section className='get-in-touch-section'>
      <div className='container'>
        <h3 className='primaryColor text-center bold'>
          {title === undefined ? local.GetInTouch : title}
        </h3>
        <Row className='mt-4'>
          <Col
            xl={col === undefined ? 10 : 12}
            lg={col === undefined ? 10 : 12}
            md={12}
            sm={12}
            xs={12}
            className='mx-auto'
          >
            <Row className='mt-4'>
              <Col xl={10} lg={10} md={12} sm={12} xs={12} className='mx-auto'>
                <Row>
                  {[
                    { type: 'text', title: 'FullName' },
                    { type: 'text', title: 'Email' },
                    { type: 'text', title: 'Mobile' },
                    { type: 'text', title: 'City' },
                  ].map((item, index) => {
                    return (
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={12}
                        xs={12}
                        key={index}
                        className='mx-auto mt-4'
                      >
                        <CustomInput type={item.type} title={item.title} />
                      </Col>
                    );
                  })}
                </Row>
                <Row>
                  <Col
                    xl={11}
                    lg={11}
                    md={11}
                    sm={12}
                    xs={12}
                    className='mx-auto mt-4'
                  >
                    <CustomTextArea title='Inquiry' placeholder='WriteQuery' />
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className='mx-auto center mt-4'
                  >
                    <CustomButton label={local.Send} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};
