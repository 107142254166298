import { local } from '../../lang/local';
import './Photos.css';
import p1 from '../../assets/images/photos/p1.png';
import p2 from '../../assets/images/photos/p2.png';
import p3 from '../../assets/images/photos/p3.png';
import p4 from '../../assets/images/photos/p4.png';
import p5 from '../../assets/images/photos/p5.png';
import p6 from '../../assets/images/photos/p6.png';

import { Row, Col, Card, CardImg } from 'reactstrap';
export const PhotosView = () => {
  return (
    <section className='photos-section'>
      <h3 className='primaryColor text-center bold'>{local.Photos}</h3>
      <div className='container'>
        <Row className='mt-4'>
          <Col xl={9} lg={11} md={12} sm={12} xs={12} className='mx-auto'>
            <Row>
              {[
                { title: 'Strawberry', photo: p1 },
                { title: 'Pears', photo: p2 },
                { title: 'Peach', photo: p3 },
                { title: 'Apple', photo: p4 },
                { title: 'Plum', photo: p5 },
                { title: 'Fish', photo: p6 },
              ].map((item, index) => {
                return (
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    key={index}
                    className='mx-auto mt-5 center'
                  >
                    <div className='card-wrapper'>
                      <Card className='class-card'>
                        <CardImg
                          alt=''
                          src={item.photo}
                          top
                          width='100%'
                          height='100%'
                        />
                      </Card>
                      <p className='mt-2 bold'>{local[item.title]}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};
