import { local } from '../../../lang/local';
import './Classifications.css';
import class1 from '../../../assets/images/classifications/class1.png';
import freshFruits from '../../../assets/images/classifications/fresh_fruits.jpg';
import frozenFruits from '../../../assets/images/classifications/frozen_fruits.jpg';
import Juices from '../../../assets/images/intro5.jpg';
import FrozenVegetables from '../../../assets/images/classifications/forzen_vegetables.jpg';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import class3 from '../../../assets/images/classifications/class3.png';

import { Row, Col, Card, CardImg } from 'reactstrap';
export const ClassificationsView = () => {
  return (
    <section className='classifications-section'>
      <div className='container'>
        <h3 className='primaryColor text-center'>{local.Classifications}</h3>
        <p className='text-center desc mt-2'>{local.OurCrops}</p>
        <Row className='mt-4'>
          <Col  xl={12} lg={20} md={15} sm={20} xs={14}  className='mx-auto'>
            <Row>
              {[
                { title: 'FreshVegetables', photo: class1 },
                { title: 'FreshFruits', photo: freshFruits },
                { title: 'FrozenVegetables', photo: FrozenVegetables },
                { title: 'FrozenFruits', photo: frozenFruits },
                { title: 'Juices', photo: Juices },
                { title: 'SeaFood', photo: class3 },
              ].map((item, index) => {
                return (
                  <Col
                    xl={2}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={5}
                    key={index}
                    className='mx-auto mt-5 center'>
                    <div className='card-wrapper'
                         data-aos="flip-left"
                         data-aos-offset="200"
                         data-aos-delay="200"
                         data-aos-duration="1000"
                         data-aos-easing="ease-in-out"
                         data-aos-mirror="true"
                         data-aos-once="false"
                         data-aos-anchor-placement="center">
                      <Card className='class-card'>
                        <CardImg
                          alt=''
                          src={item.photo}
                          top
                          width='100%'
                          height='100%'
                        />
                        <div
                          className='brightness-wrapper'
                          style={{ borderRadius: '20px' }}
                        />
                      </Card>
                      <p className='mt-2 bold'>{local[item.title]}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};
