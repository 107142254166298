import { Input, Label } from 'reactstrap';
import { getLanguage, local } from '../../lang/local';
import './CustomInput.css';
export const CustomInput = ({ title, type }: any) => {
  return (
    <div className={getLanguage() === 'en' ? 'text-start' : 'text-end'}>
      <Label className='label_input'>{local[title]}</Label>
      <div>
        <Input id='input-form-style' type={type} name={title} />
      </div>
    </div>
  );
};
