import LocalizedStrings from "react-localization";
import { ZAKANG } from "../config/staticKeys";
import { en } from "./en";
import { ar } from "./ar";
import {ILocal} from '../core/interfaces/ILocal'
export const local:ILocal= new LocalizedStrings({
  en: en,
  ar: ar,
});

export const toogleLanguage = (lang) => {
  if (lang === "en") {
    localStorage.removeItem(ZAKANG);

    local.setLanguage("en");
    localStorage.setItem(ZAKANG, "en");
  } else {
    localStorage.removeItem(ZAKANG);

    local.setLanguage("ar");
    localStorage.setItem(ZAKANG, "ar");
  }

  // window.location.href = "";
  window.location.reload();
};

export const changeLanguage = () => {
  let lang = localStorage.getItem(ZAKANG);
  if (lang !== null) {
    if (lang === "en") {
      local.setLanguage("en");

      document.title = "ALFURSAN";
    } else {
      local.setLanguage("ar");

      document.title = "ALFURSAN";
    }
  } else {
    local.setLanguage("ar");
    localStorage.setItem(ZAKANG, "en");
    document.title = "ALFURSAN";
  }
};

export const getLanguage = () => {
  let lang = localStorage.getItem(ZAKANG);
  if (lang !== null) {
    return lang;
  }
};
