import React from 'react';
import { Footer } from '../../components/Footer/Footer';
import { BusinessFeaturesController } from './BusinessFeatures/BusinessFeaturesController';
import { CertificatesController } from './Certificates/CertificatesController';
import { ClassificationsController } from './Classifications/ClassificationsController';
import { CoreValuesController } from './CoreValues/CoreValuesController';
import { GetInTouchController } from '../GetInTouch/GetInTouchController';
import { IntroController } from './Intro/IntroController';
import { OurBrandsController } from './OurBrands/OurBrandsController';
import {LogoController} from "./logo/LogoController";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


export const HomeController = () => {
    AOS.init();
  return (
      <React.Fragment>
      <LogoController />
      <IntroController />
      <OurBrandsController/>
      <ClassificationsController/>
      <CertificatesController/>
      <BusinessFeaturesController/>
      <CoreValuesController/>
      <GetInTouchController/>
      <Footer/>
      </React.Fragment>
  );
};
