import { local } from '../../lang/local';
import './Products.css';
import p1 from '../../assets/images/products/frozen-vegitables/p1.jpg';
import p2 from '../../assets/images/products/frozen-vegitables/p2.jpg';
import p3 from '../../assets/images/products/frozen-vegitables/p3.jpg';
import p4 from '../../assets/images/products/frozen-vegitables/p4.jpg';
import p5 from '../../assets/images/products/frozen-vegitables/p5.jpg';
import p6 from '../../assets/images/products/frozen-vegitables/p6.jpg';
import p8 from '../../assets/images/products/frozen-vegitables/p8.jpg';
import p9 from '../../assets/images/products/frozen-vegitables/p9.jpg';
import p10 from '../../assets/images/products/frozen-vegitables/p10.jpg';
import p11 from '../../assets/images/products/frozen-vegitables/p11.jpg';
import p12 from '../../assets/images/products/frozen-vegitables/p12.jpg';
import p13 from '../../assets/images/products/frozen-vegitables/p13.jpg';
import p14 from '../../assets/images/products/frozen-vegitables/p14.jpg';
import p15 from '../../assets/images/products/frozen-vegitables/p15.jpg';
import p16 from '../../assets/images/products/frozen-vegitables/p16.jpg';
import p17 from '../../assets/images/products/frozen-vegitables/p17.jpg';
import p18 from '../../assets/images/products/frozen-vegitables/p18.jpg';
import p19 from '../../assets/images/products/frozen-vegitables/p19.jpg';
import p20 from '../../assets/images/products/frozen-vegitables/p20.jpg';
import p21 from '../../assets/images/products/frozen-vegitables/p21.jpg';
import p22 from '../../assets/images/products/frozen-vegitables/p22.jpg';
import p23 from '../../assets/images/products/frozen-vegitables/p23.jpg';
import p24 from '../../assets/images/products/frozen-vegitables/p24.jpg';
import p25 from '../../assets/images/products/frozen-vegitables/p25.jpg';
import p26 from '../../assets/images/products/frozen-vegitables/p26.jpg';
import p27 from '../../assets/images/products/frozen-vegitables/p27.jpg';
import p28 from '../../assets/images/products/frozen-vegitables/p28.jpg';
import p29 from '../../assets/images/products/frozen-vegitables/p29.jpg';
import p30 from '../../assets/images/products/frozen-vegitables/p30.jpg';
import p31 from '../../assets/images/products/frozen-vegitables/p31.jpg';
import p32 from '../../assets/images/products/frozen-vegitables/p32.jpg';
import p33 from '../../assets/images/products/frozen-vegitables/p33.jpg';
import p34 from '../../assets/images/products/frozen-vegitables/p34.jpg';
import p35 from '../../assets/images/products/frozen-vegitables/p35.jpg';
import p36 from '../../assets/images/products/frozen-vegitables/p36.jpg';
import p37 from '../../assets/images/products/frozen-vegitables/p37.jpg';
import p38 from '../../assets/images/products/frozen-vegitables/p38.jpg';
import p39 from '../../assets/images/products/frozen-vegitables/p39.jpg';


import ff1 from '../../assets/images/products/frozen-fruits/p7.jpg';
import ff2 from '../../assets/images/products/frozen-fruits/p29.jpg';
import ff3 from '../../assets/images/products/frozen-fruits/p40.jpg';
import ff4 from '../../assets/images/products/frozen-fruits/p41.jpg';
import ff5 from '../../assets/images/products/frozen-fruits/p42.jpg';
import ff6 from '../../assets/images/products/frozen-fruits/p43.jpg';
import ff7 from '../../assets/images/products/frozen-fruits/p44.jpg';
import ff8 from '../../assets/images/products/frozen-fruits/p45.jpg';
import ff9 from '../../assets/images/products/frozen-fruits/p46.jpg';



import lemon from '../../assets/images/products/fresh-fruits/Lemon.jpg'
import mandarin from '../../assets/images/products/fresh-fruits/Mandarin.png'
import orange from '../../assets/images/products/fresh-fruits/Orange.jpg'
import peach from '../../assets/images/products/fresh-fruits/Peach.jpg'
import plum from '../../assets/images/products/fresh-fruits/Plum.jpeg'


import pineappleJuice from '../../assets/images/products/juices/pineappleJuice.jpg'
import mangoJuice from '../../assets/images/products/juices/mangoJuice.jpg'
import mixJuice from '../../assets/images/products/juices/mixJuice.jpg'
import orangeJuice from '../../assets/images/products/juices/orangeJuice.jpg'
import appleJuice from '../../assets/images/products/juices/appleJuice.jpg'

import shrimp from '../../assets/images/products/seafoods/Shrimp.jpg'
import Crab from '../../assets/images/products/seafoods/Crab.jpg'
import Squid from '../../assets/images/products/seafoods/Squid.jpg'
import TilapiaFish from '../../assets/images/products/seafoods/TilapiaFish.jpg'
import MackerelFesh from '../../assets/images/products/seafoods/MackerelFesh.jpg'
import MulletFish from '../../assets/images/products/seafoods/MulletFish.jpg'


import { Row, Col, Card, CardImg } from 'reactstrap';
import class1 from "../../assets/images/classifications/class1.png";
import freshFruits from "../../assets/images/classifications/fresh_fruits.jpg";
import FrozenVegetables from "../../assets/images/classifications/forzen_vegetables.jpg";
import frozenFruits from "../../assets/images/classifications/frozen_fruits.jpg";
import class3 from "../../assets/images/classifications/class3.png";
import {refresh} from "aos";
import {useState} from "react";
import Juices from "../../assets/images/intro5.jpg";
export const ProductsView = () => {
    const [items, setItems] = useState([
        { title: 'Artichoke', photo: p8 },
        { title: 'PeeledBroadGreenBeans', photo: p9 },
        { title: 'GreenBeans', photo: p10 },
        { title: 'Broccoli', photo: p11},
        { title: 'Cauliflower', photo: p12 },
        { title: 'Leek', photo: p13 },
        { title: 'ConeSpinach', photo: p14 },
        { title: 'SweetPotato', photo: p15 },
        { title: 'GreenPeas', photo: p16 },
        { title: 'Carrot', photo: p18 },
        { title: 'GreenOkra', photo: p22 },
        { title: 'GreenLeafMolokhia', photo: p23 },
        { title: 'ConeMolokhia', photo: p24 },
        { title: 'WinterBlend', photo: p26 },
        { title: 'LeafSpinach', photo: p27 },
        { title: 'SweetCornCobs', photo: p28 },
        { title: 'SweetCorn', photo: p29 },
        { title: 'Zucchini', photo: p30 },
    ]);

    function catClick(this: any, index: number) {
        if (index == 0){
                setItems( [
                    { title: 'Artichoke', photo: p8 },
                    { title: 'PeeledBroadGreenBeans', photo: p9 },
                    { title: 'GreenBeans', photo: p10 },
                    { title: 'Broccoli', photo: p11},
                    { title: 'Cauliflower', photo: p12 },
                    { title: 'Leek', photo: p13 },
                    { title: 'ConeSpinach', photo: p14 },
                    { title: 'SweetPotato', photo: p15 },
                    { title: 'GreenPeas', photo: p16 },
                    { title: 'Carrot', photo: p18 },
                    { title: 'GreenOkra', photo: p22 },
                    { title: 'GreenLeafMolokhia', photo: p23 },
                    { title: 'ConeMolokhia', photo: p24 },
                    { title: 'WinterBlend', photo: p26 },
                    { title: 'LeafSpinach', photo: p27 },
                    { title: 'SweetCornCobs', photo: p28 },
                    { title: 'SweetCorn', photo: p29 },
                    { title: 'Zucchini', photo: p30 },


                ]);

        }else if (index ==1){
            setItems([
                    { title: 'Orange', photo: orange },
                    { title: 'Peach', photo: peach },
                    { title: 'Plum', photo: plum },
                    { title: 'Mandarin', photo: mandarin },
                    { title: 'Lemon', photo: lemon },


                ]);
        }else if (index ==2){
            setItems([
                { title: 'GreenBeans', photo: p1 },
                { title: 'GreenPeas', photo: p2 },
                { title: 'GreenSpinach', photo: p3 },
                { title: 'PeasCarrots', photo: p4 },
                { title: 'GreenOkraZero', photo: p5 },
                { title: 'GreenLeafMolokhia', photo: p6 },
                { title: 'Artichoke', photo: p8 },
                { title: 'PeeledBroadGreenBeans', photo: p9 },
                { title: 'GreenBeans', photo: p10 },
                { title: 'Broccoli', photo: p11},
                { title: 'Cauliflower', photo: p12 },
                { title: 'Leek', photo: p13 },
                { title: 'ConeSpinach', photo: p14 },
                { title: 'SweetPotato', photo: p15 },
                { title: 'GreenPeas', photo: p16 },
                { title: 'PeasCarrots', photo: p17 },
                { title: 'Carrot', photo: p18 },
                { title: 'MixedVegetables', photo: p19 },
                { title: 'MixedVegetables4y', photo: p20 },
                { title: 'VegetablesForSoup', photo: p21 },
                { title: 'GreenOkra', photo: p22 },
                { title: 'GreenLeafMolokhia', photo: p23 },
                { title: 'ConeMolokhia', photo: p24 },
                { title: 'CaliforniaMix', photo: p25 },
                { title: 'WinterBlend', photo: p26 },
                { title: 'LeafSpinach', photo: p27 },
                { title: 'SweetCornCobs', photo: p28 },
                { title: 'SweetCorn', photo: p29 },
                { title: 'Zucchini', photo: p30 },
                { title: 'GrapeLeaves', photo: p31 },
                { title: 'Eggplant', photo: p32 },
                { title: 'Tomatoes', photo: p33 },
                { title: 'BabaGhanoush', photo: p34 },
                { title: 'pepper', photo: p35 },
                { title: 'Qalqas', photo: p36 },
                { title: 'Onions', photo: p37 },
                { title: 'GreenBean', photo: p38 },
                { title: 'Potatoes', photo: p39 },



            ]);
        }else if (index ==3){
            setItems([
                { title: 'Strawberry', photo: ff1 },
                { title: 'SweetCorn', photo: ff2 },
                { title: 'Mango', photo: ff3 },
                { title: 'Pomegranates', photo: ff4 },
                { title: 'Guava', photo: ff5 },
                { title: 'Apricot', photo: ff6 },
                { title: 'Fig', photo: ff7 },
                { title: 'PricklyPear', photo: ff8 },
                { title: 'Dates', photo: ff9 },

            ]);
        }else if (index ==4){
            setItems([
                { title: 'Apple', photo: appleJuice },
                { title: 'Mango', photo: mangoJuice },
                { title: 'Mix', photo: mixJuice },
                { title: 'Orange', photo: orangeJuice },
                { title: 'PineApple', photo: pineappleJuice },
            ]);
        }else if (index ==5){
            setItems([
                { title: 'Shrimp', photo: shrimp },
                { title: 'Squid', photo: Squid },
                { title: 'Crab', photo: Crab },
                { title: 'TilapiaFish', photo: TilapiaFish },
                { title: 'MulletFish', photo: MulletFish },
                { title: 'MackerelFesh', photo: MackerelFesh },
            ]);
        }

    }

    return (
    <section className='products-section'>
      <h3 className='primaryColor text-center bold'>{local.Products}</h3>
        <div className='classifications'>
            <Row className='mt-4'>
                <Col  xl={10} lg={20} md={15} sm={12} xs={14}  className='mx-auto'>
                    <Row>
                        {[
                            { title: 'FreshVegetables', photo: class1 },
                            { title: 'FreshFruits', photo: freshFruits },
                            { title: 'FrozenVegetables', photo: FrozenVegetables },
                            { title: 'FrozenFruits', photo: frozenFruits },
                            { title: 'Juices', photo: Juices },
                            { title: 'SeaFood', photo: class3 },
                        ].map((item, index) => {
                            return (
                                <Col
                                    xl={2}
                                    lg={3}
                                    md={4}
                                    sm={5}
                                    xs={5}
                                    key={index}
                                    className='mx-auto mt-5 center'>
                                    <div className='card-wrapper' onClick={() => catClick(index)}>
                                        <Card className='class-card' >
                                            <CardImg
                                                alt=''
                                                src={item.photo}
                                                top
                                                width='100%'
                                                height='100%'
                                            />
                                            <div
                                                className='brightness-wrapper'
                                                style={{ borderRadius: '10px' }}
                                            />
                                        </Card>
                                        <p >{local[item.title]}</p>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </Col>
            </Row>
        </div>
      <div className='container'>
        <Row className='mt-4'>
          <Col xl={9} lg={11} md={12} sm={12} xs={12} className='mx-auto'>
            <Row>
              {items.map((item, index) => {
                return (
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    key={index}
                    className='mx-auto mt-5 center'
                  >
                    <div className='card-wrapper'>
                      <Card className='class-card'>
                        <CardImg
                          alt=''
                          src={item.photo}
                          top
                          width='100%'
                          height='100%'
                        />
                        <div
                          className='brightness-wrapper'
                          style={{ borderRadius: '20px' }}
                        />
                      </Card>
                      <p className='mt-2 bold'>{local[item.title]}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};
