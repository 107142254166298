import React from 'react';
import { Footer } from '../../components/Footer/Footer';
import { local } from '../../lang/local';
import { GetInTouchController } from '../GetInTouch/GetInTouchController';
import { OurOfficesView } from './OurOfficesView';

export const OurOfficesController = () => {
  return (
    <React.Fragment>
      <OurOfficesView />
      <GetInTouchController col={12} />
      <Footer />
    </React.Fragment>
  );
};
