/* eslint-disable no-param-reassign */
/**
 * The Rule above is disabled as Redux-toolkit does NOT mutate state
 * check the link below
 * https://redux-toolkit.js.org/tutorials/quick-start#create-a-redux-state-slice
 */
import { PayloadAction } from '@reduxjs/toolkit';

const SIGN_IN_USER = (state: any, action: PayloadAction<boolean>) => {
  // TODO!store user token from the API into a cookie.
  state.isLoggedIn = action.payload;
};

const LOGOUT_USER = (state: any, action: PayloadAction) => {
  // TODO!remove stored user token cookie.
  state.isLoggedIn = action.payload;
};

export { SIGN_IN_USER, LOGOUT_USER };
