import { local } from '../../../lang/local';
import './Intro.css';
import { Col, Row } from 'reactstrap';
import img1 from '../../../assets/images/intro1.jpg';
import img2 from '../../../assets/images/intro2.jpg';
import img3 from '../../../assets/images/intro3.jpg';
import img4 from '../../../assets/images/intro4.jpg';
import img5 from '../../../assets/images/intro5.jpg';
import img6 from '../../../assets/images/intro6.jpg';
import {CCarousel, CCarouselCaption, CImage, CCarouselItem} from '@coreui/react'

export const IntroView = () => {
  return (
      <div data-aos="zoom-out"
           data-aos-offset="20"
           data-aos-delay="200"
           data-aos-duration="1000"
           data-aos-easing="ease-in-out"
           data-aos-mirror="true"
           data-aos-once="false"
           data-aos-anchor-placement="center-bottom">
        <CCarousel
                   interval={2000}
                   controls={true}
                   pause={false}
                   indicators={true} >
          <CCarouselItem>
            <CImage className="d-block w-100" src={img1} alt="slide 1" />
            <CCarouselCaption className="d-none d-md-block">
              <h5>{local.FreshVegetables}</h5>
              <p>{local.FreshVegetablesDesc}</p>
            </CCarouselCaption>
          </CCarouselItem>
          <CCarouselItem>
            <CImage  className="d-block w-100" src={img2} alt="slide 2" />
            <CCarouselCaption className="d-none d-md-block">
              <h5>{local.FreshFruits}</h5>
              <p>{local.FreshFruitsDesc}</p>
            </CCarouselCaption>
          </CCarouselItem>
          <CCarouselItem>
            <CImage  className="d-block w-100" src={img3} alt="slide 3" />
            <CCarouselCaption className="d-none d-md-block">
              <h5>{local.FrozenVegetables}</h5>
              <p>{local.FrozenVegetablesDesc}</p>
            </CCarouselCaption>
          </CCarouselItem>
          <CCarouselItem>
            <CImage  className="d-block w-100" src={img4} alt="slide 4" />
            <CCarouselCaption className="d-none d-md-block">
              <h5>{local.FrozenFruits}</h5>
              <p>{local.FrozenFruitsDesc}</p>
            </CCarouselCaption>
          </CCarouselItem>
          <CCarouselItem>
            <CImage  className="d-block w-100" src={img5} alt="slide 4" />
            <CCarouselCaption className="d-none d-md-block">
              <h5>{local.Juices}</h5>
              <p>{local.JuicesDesc}</p>
            </CCarouselCaption>
          </CCarouselItem>
          <CCarouselItem>
            <CImage  className="d-block w-100" src={img6} alt="slide 4" />
            <CCarouselCaption className="d-none d-md-block">
              <h5>{local.SeaFood}</h5>
              <p>{local.SeaFoodDesc}</p>
            </CCarouselCaption>
          </CCarouselItem>
        </CCarousel>
      </div>
  );
};
