import { local } from '../../lang/local';
import './Quality.css';
import { Row, Col } from 'reactstrap';
import React from 'react';
export const QualityView = () => {
  return (
    <div className='quality-wrapper'>
      <h3 className='primaryColor text-center bold'>{local.TheQuality}</h3>
      <section className='quality-section mt-5'>
        <div className='container'>
          {[
            'quailtyTitle1',
            'quailtyTitle2',
            'quailtyTitle3',
            'quailtyTitle4',
          ].map((item, index) => {
            return (
              <Row>
                <Col
                  key={index}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className='mx-auto mt-5'
                >
                  <p className='desc'>{local[item]}</p>
                </Col>
              </Row>
            );
          })}
        </div>
      </section>
    </div>
  );
};
