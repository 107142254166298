import React from 'react';
import { Footer } from '../../components/Footer/Footer';
import { ShippingView } from './ShippingView';

export const ShippingController = () => {
  return (
    <React.Fragment>
      <ShippingView />
      <Footer />
    </React.Fragment>
  );
};
