import { local } from '../../lang/local';
import './Shipping.css';
import { Row, Col } from 'reactstrap';
export const ShippingView = () => {
  return (
    <div className='shipping-wrapper'>
      <h3 className='primaryColor text-center bold'>
        {local.ShippingTransportation}
      </h3>
      <section className='shipping-section mt-5'>
        <div className='container'>
          {['shippingTitle1', 'shippingTitle2'].map((item, index) => {
            return (
              <Row key={index}>
                <Col
                  xl={10}
                  lg={10}
                  md={10}
                  sm={12}
                  xs={12}
                  className='mx-auto mt-5'
                >
                  <p className='desc text-center'>{local[item]}</p>
                </Col>
              </Row>
            );
          })}
        </div>
      </section>
    </div>
  );
};
