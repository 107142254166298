import { local } from '../../lang/local';
import './ContactUs.css';
import c1 from '../../assets/images/contacts/c1.png';
import c2 from '../../assets/images/contacts/c2.png';
import c3 from '../../assets/images/contacts/c3.png';

import { Row, Col, Card } from 'reactstrap';
export const ContactUsView = () => {
  return (
    <section className='contact-us-section'>
      <div className='container'>
        <h3 className='primaryColor text-center bold'>{local.ContactUs}</h3>
        <Row className='mt-4'>
          <Col xl={10} lg={10} md={12} sm={12} xs={12} className='mx-auto'>
            <Row>
              {[
                {
                  title: 'Office',
                  desc: 'StreetAddress',
                  photo: c1,
                },
                {
                  title: 'Mobile',
                  desc: `+201019882500 +201228926588`,
                  photo: c2,
                  noLang: true,
                },
                {
                  title: 'Email',
                  desc: 'info@alfursanexports.com',
                  photo: c3,
                  noLang: true,
                },
              ].map((item, index) => {
                return (
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    className='mx-auto center mt-5'
                  >
                    <div className='text-center card-wrapper'>
                      <Card className='text-center alignItem general-photo-card photo-card'>
                        <div className='card-container mt-4'>
                          <img
                            src={item.photo}
                            alt=''
                            className='imgFull'
                            key={index}
                          />
                        </div>
                        <div className='mt-5 desc'>
                          {item.noLang === undefined ? (
                            <p className='mt-3 bold'>{local[item.desc]}</p>
                          ) : (
                            <p className='mt-3 bold'>
                              {item.desc.split(' ')[0]}
                              <p className='mt-4'>{item.desc.split(' ')[1]}</p>
                            </p>
                          )}
                        </div>
                      </Card>
                      <p className='mt-3 title'>{local[item.title]}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};
