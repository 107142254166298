import React from 'react';
import { Footer } from '../../components/Footer/Footer';
import { local } from '../../lang/local';
import { GetInTouchController } from '../GetInTouch/GetInTouchController';
import { ContactUsView } from './ContactUsView';

export const ContactUsController = () => {
  return (
    <React.Fragment>
      <ContactUsView />
      <GetInTouchController title={local.CommunicateWithUs} col={12} />
      <Footer/>
    </React.Fragment>
  );
};
