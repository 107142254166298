import React from 'react';
import { Footer } from '../../components/Footer/Footer';
import { AboutUsView } from './AboutUsView';

export const AboutUsController = () => {
  return (
    <React.Fragment>
      <AboutUsView />
      <Footer />
    </React.Fragment>
  );
};
