import React from 'react';
import { Footer } from '../../components/Footer/Footer';
import { ProductsView } from './ProductsView';

export const ProductsController = () => {
  return (
    <React.Fragment>
      <ProductsView />
      <Footer />
    </React.Fragment>
  );
};
