import { AboutUsController } from '../modules/AboutUs/AboutUsController';
import { HomeController } from '../modules/Home/HomeController';
import { PhotosController } from '../modules/Photos/PhotosController';
import { ProductsController } from '../modules/Products/ProductssController';
import { ShippingController } from '../modules/Shipping/ShippingController';
import { QualityController } from '../modules/Quality/QualityController';
import { ContactUsController } from '../modules/ContactUs/ContactUsController';
import { OurOfficesController } from '../modules/OurOffices/OurOfficesController';

const routes = [
  { component: HomeController, name: 'Home', path: '/', toAside: true },
  {
    component: AboutUsController,
    name: 'aboutUs',
    path: '/aboutUs',
    toAside: true,
  },
  {
    component: PhotosController,
    name: 'Photos',
    path: '/photos',
    toAside: true,
  },
  {
    component: ProductsController,
    name: 'products',
    path: '/products',
    toAside: true,
  },
  {
    component: QualityController,
    name: 'quality',
    path: '/quality',
    toAside: true,
  },
  {
    component: ShippingController,
    name: 'shipping',
    path: '/shipping',
    toAside: true,
  },
  {
    component: ContactUsController,
    name: 'contactus',
    path: '/contactus',
    toAside: true,
  },
  {
    component: OurOfficesController,
    name: 'ouroffices',
    path: '/ouroffices',
    toAside: true,
  },
];

export default routes;
