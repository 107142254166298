import React from 'react';
import { Footer } from '../../components/Footer/Footer';
import { QualityView } from './QualityView';

export const QualityController = () => {
  return (
    <React.Fragment>
      <QualityView />
      <Footer />
    </React.Fragment>
  );
};
